/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col, Collapse, Tag } from "antd"
import {
  YoutubeOutlined,
  InstagramOutlined,
  HomeOutlined,
} from "@ant-design/icons"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const { Panel } = Collapse

const social = [
  {
    type: `youtube`,
    url: `https://www.youtube.com/sayuntv`,
  },
  {
    type: `instagram`,
    url: `https://www.instagram.com/sayun.studio`,
  },
]
const SiteIcon = ({ type }) => {
  if (type === "youtube") return <YoutubeOutlined />
  if (type === "homepage") return <HomeOutlined />
  if (type === "instagram") return <InstagramOutlined />
  else return <></>
}
const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            type
            url
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const avatar = "https://i.imgur.com/9w4b0THm.jpg"

  return (
    <div className="bio">
      <img src={avatar} className="bio-avatar" alt="사윤프로필이미지" />
      <br />
      {author?.name && (
        <>
          <p>
            <strong>{author.name}</strong>
          </p>
          <p>
            {social.map((site, i) => (
              <OutboundLink key={i} target="_blank" href={site.url}>
                <SiteIcon type={site.type} />
              </OutboundLink>
            ))}
            <small style={{ whiteSpace: "pre-line" }}>
              {author?.summary || null}
            </small>
          </p>
          <br />
          <br />
        </>
      )}
      {/* <Row gutter={[24, 4]} style={{ textAlign: "left" }}>
        <Col xl={8} lg={8} md={8} sm={12} xs={24}>
          <Collapse accordion expandIconPosition="right">
            <Panel header="이력 및 기술">
              - B.S. in Computer Science <br />
              - Ebay Korea Back-end Developer
              <br />- Kakao Back-end Developer
            </Panel>
          </Collapse>
        </Col>
        <Col xl={8} lg={8} md={8} sm={12} xs={24}>
          <Collapse accordion expandIconPosition="right">
            <Panel header="요즘 진행중인 것">
              <Tag color="purple">STUDY</Tag> Deep learning
              <br />
              <Tag color="purple">WEBTOON</Tag> AI관련 웹툰 구상중
            </Panel>
          </Collapse>
        </Col>
        <Col xl={8} lg={8} md={8} sm={12} xs={24}>
          <Collapse accordion expandIconPosition="right">
            <Panel header="중단기 목표">
              <Tag color="purple">SERVICE</Tag> 자동 썸네일 생성기
            </Panel>
          </Collapse>
        </Col>
      </Row> */}
    </div>
  )
}

export default Bio
